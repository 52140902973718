import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { getVhUrl, VhPage } from '../../utils/url-helper';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AboutUsDialogComponent } from '../dialogs/about-us-dialog/about-us-dialog.component';
import { ContactUsDialogComponent } from '../dialogs/contact-us-dialog/contact-us-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { SUPPORT_PHONE } from '../../constants/misc';
import { MatIconButton } from '@angular/material/button';
import { MiddleLayoutComponent } from '../layouts/middle-layout/middle-layout.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'vh-primary-header',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    MatIcon,
    MatIconButton,
    MiddleLayoutComponent,
    MatMenu,
    MatMenuTrigger,
    NgTemplateOutlet,
    MatDivider
  ],
  templateUrl: './primary-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryHeaderComponent {
  @HostBinding('class')
  public cssClasses = 'z-10 block mat-elevation-z2';

  @Input()
  public get isWhite(): boolean { return this._isWhite; }
  public set isWhite(value: boolean) {
    this._isWhite = value;
    this.cssClasses = `z-10 block ${this.isWhite ? 'mat-elevation-z2' : ''}`;
  }
  private _isWhite = true;

  public homeUrl = getVhUrl(VhPage.Home);
  public faqUrl = getVhUrl(VhPage.Faq);
  public appName = environment.appTitle;
  public supportPhone = SUPPORT_PHONE;

  constructor(private dialog: MatDialog) { }

  public openAboutUs() {
    this.dialog.open(AboutUsDialogComponent, { maxWidth: '600px' });
  }

  public openContactUs() {
    this.dialog.open(ContactUsDialogComponent);
  }
}
