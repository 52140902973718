import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, Subject, take, takeUntil, tap } from 'rxjs';
import { MatInput } from '@angular/material/input';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { SnackBarService } from '../../../../services/snack-bar.service';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { PlatformService } from '../../../../services/platform.service';
import { PropertyService } from '../../../../services/property.service';
import { ISuggestion } from '../../../../models/api-models/suggestion';

type onChangeFunctionType = (value: ISuggestion | null) => void;
type onTouchFunctionType = () => void;

@Component({
  selector: 'vh-destination-input',
  standalone: true,
  imports: [
    MatInput,
    MatOption,
    ReactiveFormsModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    AsyncPipe,
    MatDivider,
    NgIf,
    NgFor,
    NgClass
  ],
  providers: [{ provide: MatFormFieldControl, useExisting: DestinationInputComponent }],
  templateUrl: './destination-input.component.html'
})
export class DestinationInputComponent implements OnInit, OnDestroy, MatFormFieldControl<ISuggestion | null>, ControlValueAccessor {
  @ViewChild(MatInput, { read: ElementRef, static: true })
  public input!: ElementRef;

  @HostBinding()
  public id = `destination-input-id-${DestinationInputComponent.nextId++}`;
  public static nextId = 0;

  @Input()
  public set placeholder(pl: string) {
    this._placeholder = pl;
    this.stateChanges.next();
  }
  public get placeholder() {
    return this._placeholder;
  }
  private _placeholder: string = '';

  @Input()
  set value(value: ISuggestion | null) {
    this._value = value;
    this.valueHelper.setValue(value as any);

    this.stateChanges.next();
  }
  get value() {
    return this._value;
  }
  public _value: ISuggestion | null = null;

  get empty(): boolean {
    return !this.value;
  }

  @HostBinding('class.floated')
  get shouldLabelFloat(): boolean {
    return true;
  }

  @Input()
  public required: boolean = false;

  @Input()
  public disabled: boolean = false;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public get errorState(): boolean {
    if (this.ngControl != null) {
      return this.errorMatcher.isErrorState(this.ngControl.control, null);
    }
    return false;
  }

  public focused: boolean = false;
  public controlType = 'custom-form-field';
  public stateChanges = new Subject<void>();
  public valueHelper = new FormControl<ISuggestion | null>(null);
  public onChange: onChangeFunctionType = () => { };
  public onTouch: onTouchFunctionType = () => { };
  public typing = new BehaviorSubject<boolean>(false);

  private _unsubscribe = new Subject<void>();
  private readonly MIN_WORD_LENGTH = 3;
  private readonly DEBOUNCE_TIME_MS = 500;

  constructor(
    private focusMonitor: FocusMonitor,
    private errorMatcher: ErrorStateMatcher,
    private _snackBar: SnackBarService,
    private _platformService: PlatformService,
    public propertyService: PropertyService,
    @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.focusMonitor.monitor(this.input).pipe(takeUntil(this._unsubscribe))
      .subscribe(focused => {
        this.focused = !!focused;
        this.stateChanges.next();
      });

    this.focusMonitor.monitor(this.input).pipe(take(1)).subscribe(() => this.onTouch());

    this.valueHelper.valueChanges.pipe(
      tap(() => {
        if (this.typing.value !== true) {
          this.typing.next(true);
        }
      }),
      debounceTime(this.DEBOUNCE_TIME_MS),
      distinctUntilChanged(),
      filter((term: any) => term !== null && term !== undefined && term.length >= this.MIN_WORD_LENGTH),
      takeUntil(this._unsubscribe))
      .subscribe(filter => {
        this.typing.next(false);

        if (filter) {
          this.propertyService.getSearchSuggestions(filter);
        } else {
          this.onChange(null);
        }
      });
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();

    this.focusMonitor.stopMonitoring(this.input);
    this.stateChanges.complete();
  }

  public writeValue(obj: ISuggestion | null): void {
    this.value = obj;

    if (obj) {
      this.propertyService.currentSuggestions$.pipe(take(1)).subscribe(currentSuggestions => {
        for (const suggestion of currentSuggestions) {
          if (suggestion.place_id === obj.place_id) {
            this.valueHelper.setValue(suggestion, { emitEvent: false });
          }
        }
      });
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.valueHelper.disable();
    } else {
      this.valueHelper.enable();
    }

    this.stateChanges.next();
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(): void {
    this.focusMonitor.focusVia(this.input, 'program');
  }

  public displayFn(option: ISuggestion): string {
    return option?.destination ?? '';
  }

  public async onPlaceSelected(place: ISuggestion) {
    if (!place) {
      this.onChange(null);
      return;
    }

    this.onChange(place);
  }
}
