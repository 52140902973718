<input matInput #destInput="matInput" [placeholder]="placeholder" [formControl]="valueHelper" [matAutocomplete]="auto"
    class="outline-none border-none text-base leading-4 w-full" aria-label="destination input"
    [ngClass]="{'placeholder:text-vhNeutral-10': !destInput.focused}" />
<mat-autocomplete #auto="matAutocomplete" class="!rounded-[10px]" [displayWith]="displayFn"
    (optionSelected)="onPlaceSelected($event.option.value)">
    <mat-option *ngFor="let option of (propertyService.currentSuggestions$ | async)"
        [value]="option">{{option.destination}}</mat-option>
    <mat-option
        *ngIf="!(propertyService.currentSuggestions$ | async)?.length && (propertyService.suggestionsLoading$ | async) === false && (typing | async) === false"
        [disabled]="true">
        {{valueHelper.value ? 'No destinations found' : 'Start typing to load destinations'}}
    </mat-option>

    <ng-container *ngIf="((propertyService.suggestionsLoading$ | async) === false) && (valueHelper.value)">

        <mat-divider *ngIf="(propertyService.currentSuggestions$ | async)?.length"></mat-divider>
        <mat-option [value]="null">Clear input</mat-option>

    </ng-container>
</mat-autocomplete>