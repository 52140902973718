<ng-template #pageLinks let-isMenuItem="isMenuItem">
    <button class="cursor-pointer bg-transparent border-none !p-0 text-left" aria-label="About Us"
        (click)="openAboutUs()">
        <span class="tracking-normal text-base text-vhNeutral-10"
            [class.!text-white]="!isWhite && !isMenuItem">About</span>
    </button>

    <a [routerLink]="faqUrl" [queryParamsHandling]="'preserve'" class="hover:!no-underline" aria-label="FAQ">
        <span class="tracking-normal text-base">FAQ</span>
    </a>

    <button class="cursor-pointer bg-transparent border-none !p-0" aria-label="Contact Us" (click)="openContactUs()">
        <span class="tracking-normal text-base text-vhNeutral-10"
            [class.!text-white]="!isWhite && !isMenuItem">Contact</span>
    </button>
</ng-template>

<ng-template #phone>
    <div class="flex items-center">
        <mat-icon class="!h-4 !leading-4 mr-2" svgIcon="vhc-phone-alt"></mat-icon>
        <a class="text-base" href="tel:{{supportPhone}}" aria-label="Support phone">{{supportPhone}}</a>
    </div>
</ng-template>

<vh-middle-layout class="py-[14px]"
    [ngClass]="{'bg-white text-vhNeutral-10': isWhite, 'bg-transparent text-white': !isWhite}">
    <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4 grow mr-4 lg:grow-0">
            <a [routerLink]="homeUrl" aria-label="Navigate home" class="col-span-12">
                <img alt="{{appName + ' logo'}}"
                    src="{{isWhite ? 'assets/images/vhc-logo.svg' : 'assets/images/vhc-logo-blank.svg'}}"
                    class="h-12 hidden md:block">

                <mat-icon [svgIcon]="isWhite ? 'vhc-badge-color':'vhc-badge'"
                    class="md:!hidden !w-10 !h-10 !leading-10"></mat-icon>
            </a>

            <ng-content></ng-content>
        </div>

        <div class="items-center gap-8 hidden lg:flex">
            <ng-container *ngTemplateOutlet="pageLinks"></ng-container>
            <ng-container *ngTemplateOutlet="phone"></ng-container>
        </div>

        <button [matMenuTriggerFor]="menu" mat-icon-button aria-label="Open side menu" class="lg:!hidden">
            <mat-icon svgIcon="vhc-hamburger"></mat-icon>
        </button>

        <mat-menu #menu="matMenu" class="!min-w-80 !rounded-[10px]">
            <div class="px-4 py-2 w-full">
                <div class="text-xs text-vhNeutral-50">MAIN</div>

                <div class="flex flex-col gap-4 items-start pl-4 pt-4">
                    <ng-container *ngTemplateOutlet="pageLinks; context: { isMenuItem: true }"></ng-container>
                </div>

                <div class="py-4">
                    <mat-divider></mat-divider>
                </div>

                <div class="text-xs text-vhNeutral-50">CALL</div>

                <div class="pl-4 pt-4">
                    <ng-container *ngTemplateOutlet="phone"></ng-container>
                </div>
            </div>
        </mat-menu>
    </div>
</vh-middle-layout>